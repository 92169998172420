// Contact Form Handler

( function( $, window, document, undefined ) {
  
  const $contact_form = $('#contact_form');
  const $submit_button = $contact_form.find('#contact_form_submit');

  if ( $contact_form.length ) {
    const _contact_form = new ContactForm( $contact_form );

    // Stop the browser showing it's default error handling UI, only when the JS has loaded
    $contact_form.attr( 'novalidate', 'true' );

    // Listen for bubbled events from button
    // Submit events fire default HTML5 validation as well
    $submit_button.on( 'click', (event) => {

      if ( !_contact_form.validate() ) {

        // Scroll to and focus first error
        let error_found = false;
        $.each( _contact_form.fields, ( index, field ) => {
          if ( field.has_error && !error_found ) {
            $('html, body').animate({
              scrollTop: field.$field.offset().top - 100
            }, 400 );

            field.$field.focus();

            error_found = true;
          }
        } );

        event.preventDefault();
        return false;

      }

    } );

    $contact_form.on( 'submit', (event) => {
      $submit_button.html( 'Sending&hellip;' );
    } );
  }

  // Listen for change events and hide field errors
  $contact_form.find('input, textarea, select').on( 'change', (event) => {
    let $this = $(event.target),
        formField = new FormField( $this );

    $this.trigger( 'remove_error' );
  } );

} )( jQuery, window, document );

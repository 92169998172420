// Newsletter Form Handler

( function( $, window, document, undefined ) {
  
  let $newsletterForm = $('#mc-embedded-subscribe-form');

  if ( $newsletterForm.length ) {
    let _newsletterFormController = new NewsletterFormController( $newsletterForm );

    // Stop the browser showing it's default error handling UI, only when the JS has loaded
    $newsletterForm.attr( 'novalidate', 'true' );

    // Listen for bubbled events from button
    // Submit events fire default HTML5 validation as well
    $newsletterForm.find('#mc-embedded-subscribe').on( 'click', (event) => {

      if ( !_newsletterFormController.validate() ) {
        event.preventDefault();
        return false;
      }

    } );

    $newsletterForm.on( 'submit', (event) => {
      $newsletterForm.find('#mc-embedded-subscribe').html( 'Sending&hellip;' );
    } );
  }

  // Listen for change events and hide field errors
  $newsletterForm.find('input, textarea, select').on( 'change', (event) => {
    let $this = $(event.target),
        formField = new FormField( $this );

    $this.trigger( 'removeError' );
    $this.parent().trigger( 'removeError' );
  } );

} )( jQuery, window, document );

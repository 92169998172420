// Hero image parallax

( function( $, window, document, undefined ) {

	const $hero = $('.js-hero');
	const $heroParallax = $('.js-hero__parallax');

	const parallaxPercentage = 0.6;

	if ( !$hero.length || !$heroParallax.length ) {
		return;
	}

	/**
	 * Get the new position of the element after scroll.
	 * @param  {Boolean} followScroll true for the movement to follow the scroll direction,
	 *                                false for inverse.
	 * @return {Number}  The new position, relative to the element, in pixels.
	 */
	function getPosition( followScroll = false ) {
		const scrolled = $(window).scrollTop();
		return scrolled * parallaxPercentage * ( followScroll ? 1 : -1 );
	}

	function updateParallax() {
		$heroParallax.css( 'transform', `translate3d(0, ${ getPosition( false ) }px, 0)` );
	}

	function hideShade() {
		$hero.addClass('hero--shade-hidden');
	}

	function showShade() {
		$hero.removeClass('hero--shade-hidden');
	}

	// ---------------------------------
	// EVENTS
	// ---------------------------------
	function onScroll( event ) {
		updateParallax();

		if ( $(window).scrollTop() < 60 ) {
			showShade();
		} else {
			hideShade();
		}
	}


	// ---------------------------------
	// Setup
	// ---------------------------------

	function init() {
		if ( $(window).scrollTop() > 0 ) {
			onScroll();
		}
		$(window).scroll( onScroll );
	}

	init();
	
} )( jQuery, window, document );

// Set overlap margins based on element height
// CSS cannot solve this because the vertical margin's are always based on the element width

( function( $, window, document, undefined ) {

	const percentages = {
		overlap_small: 0.075,
		overlap_medium: 0.5,
		overlap_large: 0.85
	};

	const $overlapping_elements = $('.js-overlap');

	function setOverlaps() {
		$overlapping_elements.each( (index, element) => {
			const $element = $(element);
			const $block = $element.children().eq(0);
			const overlapTop = $element.attr('data-overlap-top');
			const overlapBottom = $element.attr('data-overlap-bottom');

			let overlapAmount = 0;

			// Top Overlap
			if ( overlapTop && percentages[ overlapTop ] ) {
				overlapAmount = percentages[ overlapTop ];

				if ( $('body').hasClass('t-one-cw') && 'overlap_large' == overlapTop ) {
					overlapAmount = 1;
				}

				$element.css( 'margin-top', $element.outerHeight() * overlapAmount * -1 );
			}

			// Bottom Overlap
			if ( overlapBottom && percentages[ overlapBottom ] ) {
				overlapAmount = percentages[ overlapBottom ];

				if ( $('body').hasClass('t-one-cw') && 'overlap_large' == overlapBottom ) {
					overlapAmount = 1;
				}

				$element.css( 'margin-bottom', $element.outerHeight() * overlapAmount * -1 );
			}
		} );
	}

	setOverlaps();
	$(window).on( 'resize', debounce( setOverlaps, 150 ) );

} )( jQuery, window, document );

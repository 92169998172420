// Hero image parallax

( function( $, window, document, undefined ) {

	$gallery_items = $('.lightbox');

	if ( $gallery_items.length ) {

		$gallery_items.featherlightGallery();

	}
	
} )( jQuery, window, document );

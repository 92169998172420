// Gallery controller

( function( $, window, document, undefined ) {

	const $postContainer = $('.js-gallery-posts-container');
	const $loadMore = $('.js-loadmore');
	const $progress = $('.js-progress');

	/**
	 * Toggle the loading state and spinner on the element.
	 * @param  {jQuery}  $element The element to add the loading state to.
	 * @param  {Boolean} state    True to force the state to always be added, false to always be removed.
	 */
	function toggleLoadingIndicator( $element, state = null ) {
		$element.toggleClass( 'is-loading', state );
	}

	/**
	 * Fetch data from WordPress via AJAX.
	 * @param  {Object}   data     An object to send with the POST request.
	 *                             The keys will be available to PHP in the _POST superglobal.
	 * @param  {Function} callback A success callback function.
	 * @return {Deferred}          A jQuery Deferred object for the AJAX call.
	 */
	function fetch( data, callback ) {
		return $.ajax( {
			url: wp.ajax_url,
			type: 'POST',
			data,
			// contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			beforeSend: () => {
				// Force container height to minimise screen bounce
				$postContainer.css( 'height', $postContainer.outerHeight() );

				// show loading indicator
				toggleLoadingIndicator( $postContainer.closest('.ajax-content') );
			},
			success: callback,
			error: ( jqXHR, textStatus, errorThrown ) => {
				if ( console.error ) {
					console.error( jqXHR.status, jqXHR.statusText );
				} else {
					console.log( jqXHR.status, jqXHR.statusText );
				}
			},
			complete: () => {
				// Reset container height when images are loaded
				$postContainer.imagesLoaded( () => {
					$postContainer.css( 'height', '' );
				} );

				// hide loading indicator
				toggleLoadingIndicator( $postContainer.closest('.ajax-content') );
			}
		} );
	}

	/**
	 * Update progress component.
	 * @param  {Integer} value The amount of progress made.
	 *                         If 'max' is not passed this will be added to the current progress value.
	 * @param  {Integer} max   Optional, The maximum amount of items.
	 */
	function updateProgress( value, max = null ) {
		const $progressValue = $progress.find('.js-progress__value');
		const $progressMax = $progress.find('.js-progress__max');
		const $progressBar = $progress.find('.js-progress__bar');

		if ( !max ) {
			value += parseInt( $progressBar.attr('value') );
			max = parseInt( $progressBar.attr('max') );
		}

		// Limit value to max
		value = Math.min( value, max );

		$progressBar.attr( 'value', value );
		$progressValue.text( value );

		if ( max ) {
			$progressBar.attr( 'max', max );
			$progressMax.text( max );
		}
	}

	/**
	 * Show/Hide the load more button.
	 */
	function toggleLoadMore() {
		$loadMore.toggle( wp.gallery_params.max_num_pages > wp.gallery_params.paged );
	}

	// Not using arrow function to get 'this' context on triggering element
	$('.js-gallery-filter').change( function(event) {
		const filter = this.value;

		fetch( 
			{
				action: 'pdb_gallery_filter',
				value: filter
			},
			( data ) => {
				// Update state
				wp.gallery_params.paged = 1;
				wp.gallery_params.query_vars = data.query_vars;
				wp.gallery_params.max_num_pages = data.max_num_pages;

				$postContainer.html( data.html_content );

				toggleLoadMore();
				updateProgress( wp.gallery_params.query_vars.posts_per_page, data.found_posts );
			}
		);
	} );

	$loadMore.click( function(event) {
		$loadMore.html( $loadMore.attr('data-text-loading') ).attr( 'disabled', 'disabled' );

		fetch( 
			{
				action: 'pdb_gallery_loadmore',
				query: wp.gallery_params.query_vars,
				page: wp.gallery_params.paged + 1, // Fetch next page
			},
			( data ) => {
				if ( data ) {
					// Update state
					wp.gallery_params.paged++;

					$postContainer.append( data.html_content );
				} else {
					// No data returned, assume end of list
					wp.gallery_params.paged = wp.gallery_params.max_num_pages;
				}

				toggleLoadMore();
				updateProgress( wp.gallery_params.query_vars.posts_per_page );
			}
		).always( () => {
			$loadMore.html( $loadMore.attr('data-text') ).removeAttr('disabled');
		} );

		event.preventDefault();
		return false;
	} );
	
} )( jQuery, window, document );

// Init Featured Posts Carousel components

( function( $, window, document, undefined ) {

	const nextArrowHTML = `<button type="button" class="slick-next"><span class="sr-only">Next slide</span><svg class="svg-icon svg-chevron svg-chevron--right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.84 9.46" aria-hidden="true" focusable="false"><path d="M0 1.11l3.61 3.62L0 8.35l1.11 1.11 4.73-4.73L1.11 0z"></path></svg></button>`;

	const $components = $('.js-featured-posts-carousel');
	const params = {
		autoplay: true,
		autoplaySpeed: 4000,
		arrows: true,
		nextArrow: nextArrowHTML,
		dots: true,
		adaptiveHeight: false,
		draggable: false,
		fade: true,
		speed: 600,
		easing: 'easeOutCubic',
		responsive: [
			{
				breakpoint: 851,
				settings: "unslick"
			}
		]
	};

	$components.slick( params );
	
} )( jQuery, window, document );

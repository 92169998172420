// Timelapse switcher

( function( $, window, document, undefined ) {

	const cookieName = 'pdb_progress_timelapse_chosen';
	let currentView = cookie( 'pdb_progress_timelapse_chosen' ) || '#view-one';

	const $viewButtons = $('.js-view-switcher');
	const $views = $('.js-view');

	/**
	 * Switch to another view.
	 * @param  {string} viewID The ID for a view element.
	 */
	function switchView( viewID ) {
		const $view = $( viewID );

		if ( $view.length ) {
			// Hide other views
			$views.addClass('is-hidden');
			$viewButtons.removeClass('is-active');

			$view.removeClass('is-hidden');
			$( `[data-view="${viewID}"]` ).addClass('is-active');

			cookie( 'pdb_progress_timelapse_chosen', viewID, 30 );
		}
	}


	if ( $viewButtons.length && $views.length ) {

		switchView( currentView );

		$viewButtons.on( 'click touchend', (event) => {
			let $target = $(event.target);

			if ( !$target.is('.js-view-switcher') ) {
				$target = $target.closest('.js-view-switcher');
			}

			switchView( $target.data('view') );

			event.preventDefault();
			event.stopPropagation();
			return false;
		} );

	}
	
} )( jQuery, window, document );

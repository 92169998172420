// Hover animations touch functionality

( function( $, window, document, undefined ) {

	// Promo Box animations
	( function() {
		
		let $animated = $('.promo-box');

		if ( $animated.length ) {
			$animated.on( 'touchend', (event) => {
				let $target = $(event.target);

				if ( !$target.is('.promo-box') ) {
					$target = $target.closest('.promo-box');
				}

				if ( !$target.hasClass( 'has-hover' ) ) {
					$animated.filter('.has-hover').removeClass( 'has-hover' );
					$target.addClass( 'has-hover' );

					event.preventDefault();
					event.stopPropagation();
					return false;
				}
			} );
		}

	} )();
	
} )( jQuery, window, document );

// Convert background colors applied via wysiwyg editor into highlight classes

( function( $, window, document, undefined ) {

	let $highlightedText = $('.t-one-cw [style*="color:"][style*="background-color:"]');

	if ( $highlightedText.length ) {
		$highlightedText.each( (index, text) => {
			const $text = $(text);
			const color = $text.css('color');
			const colorKey = getThemeColorKey( rgb2hex( color ) );

			if ( colorKey ) {
				$text.addClass( `highlight highlight--${colorKey}` );
				$text.css( {
					color: '',
					backgroundColor: ''
				} );
			}

		} );
	}
	
} )( jQuery, window, document );

// Contact Form Validation

( function( $, window, document, undefined ) {

	/**
   * Represents a single form field.
   * Handles showing errors and validating common field types.
   */
  let FormField = class FormField {
    constructor( $field ) {
      let _ = this;

      _.$field = $field;
      _.hasError = false;

      $field.on( 'removeError', () => _.removeError() );
    }

    /**
     * Alias val() function from jQuery.
     */
    val() {
      let _ = this;

      return _.$field.val();
    }
  
    /**
     * Show an error attached to the field.
     * @param {String} message The error message to show.
     * @return {self}
     */
    addError( message ) {
      let _ = this,
          fieldMessage = new FormFieldMessage( _.$field );

      _.$field.addClass('has-error');
      
      fieldMessage.setStatus( 'error' ).setMessage( message );
      _.hasError = true;

      return _;
    }

    /**
     * Remove an error being shown on the field.
     * @return {self}
     */
    removeError() {
      let _ = this,
          fieldMessage = new FormFieldMessage( _.$field );

      _.$field.removeClass('has-error');

      fieldMessage.hide();
      _.hasError = false;

      return _;
    }

    /**
     * Check if the field is required and if it is,
     * whether it has a non empty value.
     * @return {Boolean}
     */
    isRequiredAndValid() {
      let _ = this;

      if ( 'checkbox' == _.$field.attr('type') ) {
        return _.$field.is('[required]') && _.$field.prop('checked');
      } else {
        return _.$field.is('[required]') && _.$field.val() !== "";
      }
    }

    /**
     * Check if the field has a minimum length
     * determined by it's HTML attributes.
     * @return {Boolean}
     */
    hasMinLength() {
      let _ = this,
          // Force string type
          value = _.$field.val() + "",
          minLength = _.$field.attr( 'minlength' );

      return value.length >= minLength;
    }

    /**
     * Check if the field is a standard email format.
     * @return {Boolean}
     */
    isEmail() {
      let _ = this,
          emailRegex = /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}$/i;

      return emailRegex.test( _.$field.val() );
    }

    /**
     * Check if the field is a standard phone number format.
     * Because some people may wish to write values such as
     *   "Day 0121... Night 07999..."
     * this check only requires at least 9 numbers in the field.
     * @return {Boolean}
     */
    isPhone() {
      let _ = this,
          replaceRegex = /[^0-9+]/g,
          phoneValue = _.$field.val().replace( replaceRegex, '' );

      return phoneValue.length >= 9;
    }

    /**
     * Test if the field value matches a specific regex pattern.
     * @param  {String|RegExp} regex A string pattern or a regex object. 
     * @return {Boolean}
     */
    matchesPattern( regex ) {
      let _ = this;

      return regex.test( _.$field.val() );
    }
  };

  /**
   * A single message element attached to a form field.
   * The message can represent information or an error in the value entered.
   */
  let FormFieldMessage = class FormFieldMessage {
    constructor( $field ) {
      let _ = this;

      _.$field = $field;
      
      _._elementSelector = '.js-input-message';
      _._animationSpeed = 250;

      _.$message = _.getElement();
    }

    /**
     * Get a jQuery element representing a 
     * message attached to the form field.
     * If there is not a message element, one will be created.
     * @return {jQuery} The message element, found or creeated.
     */
    getElement() {
      let _ = this,
          $message = _.$field.siblings( _._elementSelector );

      if ( !$message.length ) {
        $message = $( `<p class="input-message js-input-message" data-field="#${ _.$field.attr('id') }" style="display:none;"></p>` );
        // Insert into DOM
        _.$field.after( $message );
      }

      return $message;
    }

    /**
     * Set the status of the message.
     * @param {String} status The status to set. Can be anything, however 
     *                        some choices are: notice, info, error.
     * @return {self}
     */
    setStatus( status ) {
      let _ = this;

      _.$message.addClass( `input-message--${status} js-input-message--${status}` );

      return _;
    }

    /**
     * Set the message text.
     * @param {String} message The text to set.
     * @return {self}
     */
    setMessage( message ) {
      let _ = this;

      _.$message.html( message );

      if ( !_.$message.is(':visible') ) {
        _.show();
      } else {
        _.blink();
      }

      return _;
    }

    /**
     * Show the message.
     * @return {self} 
     */
    show() {
      let _ = this;

      _.$message.slideDown( _._animationSpeed );

      return _;
    }

    /**
     * Make the message blink once to attract attention.
     * @return {self} 
     */
    blink() {
      let _ = this;

      _.$message.hide().fadeIn( _._animationSpeed );

      return _;
    }

    /**
     * Hide the message.
     * @return {self} 
     */
    hide() {
      let _ = this;

      _.$message.slideUp( _._animationSpeed );

      return _;
    }
  };

  let NewsletterFormController = class NewsletterFormController {
    constructor( $form ) {
      let _ = this;

      _.$form = $form;

      _.fields = {
        first_name: new FormField( _.$form.find('#mce-FNAME') ),
        last_name: new FormField( _.$form.find('#mce-LNAME') ),
        email: new FormField( _.$form.find('#mce-EMAIL') ),
        gdpr_consent: new FormField( _.$form.find('#gdpr_consent') ),
        gdpr_consent_parent: new FormField( _.$form.find('#gdpr_consent_parent') )
      };
    }

    /**
     * Validate the form.
     * @return {Boolean} True if the form validated with no errors,
     *                   false otherwise.
     */
    validate() {
      let _ = this,
          fields = _.fields,
          validated = true;

      if ( !fields.first_name.isRequiredAndValid() ) {
        fields.first_name.addError( 'Enter your first name' );
        validated = false;
      }

      if ( !fields.last_name.isRequiredAndValid() ) {
        fields.last_name.addError( 'Enter your last name' );
        validated = false;
      }


      if ( !fields.email.isRequiredAndValid() ) {
        fields.email.addError( 'Enter your email' );
        validated = false;
      } else if ( !fields.email.isEmail() ) {
        fields.email.addError( 'Enter a valid email' );
        validated = false;
      }


      if ( !fields.gdpr_consent.isRequiredAndValid() ) {
        fields.gdpr_consent_parent.addError( 'You must tick the checkbox above and opt-in to receive our newsletter' );
        validated = false;
      }


      return validated;
    }
  };


  // Expose API
  window.FormField = FormField;
  window.FormFieldMessage = FormFieldMessage;
  window.NewsletterFormController = NewsletterFormController;
	
} )( jQuery, window, document );

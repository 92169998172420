// Link up anchor scroll

( function( $, window, document, undefined ) {

	// Select all links with hashes
	$('a[href*="#"]')
	  // Remove links that don't actually link to anything
	  .not('[href="#"]')
	  .not('[href="#0"]')
	  .click(function(event) {
	    // On-page links
	    if (
	      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
	      location.hostname == this.hostname
	    ) {
	    	const isInMobileMenu = $(this).closest('#main-nav-mobile').length != 0;

	    	if ( isInMobileMenu && window.mobileMenu.isOpen() ) {
	    		window.mobileMenu.close();
	    	}

	      // Figure out element to scroll to
	      var $target = $(this.hash);
	      $target = $target.length ? $target : $('[name=' + this.hash.slice(1) + ']');
	      // Does a scroll target exist?
	      if ($target.length) {
	        window.SmoothScroll.toElement( $target );
	      }
	    }
	  });
	
} )( jQuery, window, document );

// Resize height of recent posts

( function( $, window, document, undefined ) {

	var recentPostImage = $('.recent-posts .img-square-wrap img');
	var fourGrid = $('.four-grid .four-item img');

	if ( recentPostImage.length || fourGrid.length )  {
		function fixPostHeight($elem) {
			$elem.each( function() {
				var itemWidth = $(this).width();
				$(this).height(itemWidth);
			});
		}

		$(window).on("load resize", function() {
			fixPostHeight(recentPostImage);
			fixPostHeight(fourGrid);
		});
	}

} )( jQuery, window, document );

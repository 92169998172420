// Contact Form Validation

( function( $, window, document, undefined ) {

	/**
   * Represents a single form field.
   * Handles showing errors and validating common field types.
   */
  let FormField = class FormField {
    constructor( $field ) {
      let _ = this;

      _.$field = $field;
      _.has_error = false;

      $field.on( 'remove_error', () => _.remove_error() );
    }

    /**
     * Alias val() function from jQuery.
     */
    val() {
      let _ = this;

      return _.$field.val();
    }

    /**
     * Check if this field is a checkbox.
     * @return {Boolean}
     */
    is_checkbox() {
      let _ = this;
      let type = _.$field.attr('type');

      return type && 'checkbox' == type;
    }

    /**
     * Check if this field is a radio button.
     * @return {Boolean}
     */
    is_radio() {
      let _ = this;
      let type = _.$field.attr('type');

      return type && 'radio' == type;
    }
  
    /**
     * Show an error attached to the field.
     * @param {String} message The error message to show.
     * @return {self}
     */
    add_error( message ) {
      let _ = this,
          field_message = new FormFieldMessage( _.$field );

      _.$field.addClass('has-error');
      
      field_message.set_status( 'error' ).set_message( message );
      _.has_error = true;

      return _;
    }

    /**
     * Remove an error being shown on the field.
     * @return {self}
     */
    remove_error() {
      let _ = this,
          field_message = new FormFieldMessage( _.$field );

      _.$field.removeClass('has-error');

      field_message.hide();
      _.has_error = false;

      return _;
    }

    /**
     * Check if the field is required and if it is,
     * whether it has a non empty value.
     * @return {Boolean}
     */
    is_required_and_valid() {
      let _ = this;

      if ( _.is_radio() ) {

        let $radios = $(`[name="${ _.$field.attr('name') }"]`);
        let radios_required = $radios.filter('[required]');
        let $radios_checked = $radios.filter(':checked');

        return radios_required.length && $radios_checked.length;

      } else if ( _.is_checkbox() ) {

        return _.$field.is('[required]') && _.$field.prop('checked');

      } else {

        return (
          // If optional, any value is valid
          !_.$field.is('[required]') ||
          // Otherwise, check the value is not blank
          ( _.$field.is('[required]') && _.val() !== "" )
        );

      }
    }

    /**
     * Check if the field has an empty value.
     * @return {Boolean}
     */
    is_empty() {
      let _ = this;

      return _.val() === "";
    }

    /**
     * Check if the field value has a minimum length
     * as determined by it's HTML attributes.
     * @return {Boolean}
     */
    is_min_length() {
      let _ = this,
          // Force string type
          value = _.$field.val() + "",
          min_length = _.$field.attr( 'minlength' );

      // If the attribute is not present, any length value will equate to true
      if ( !min_length ) {
        return true;
      }

      return value && min_length && value.length >= min_length;
    }

    /**
     * Check if the field is a standard email format.
     * @return {Boolean}
     */
    is_email() {
      let _ = this,
          email_regex = /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}$/i;

      return email_regex.test( _.val() );
    }

    /**
     * Check if the field is a standard phone number format.
     * Because some people may wish to write values such as
     *   "Day 0121... Night 07999..."
     * this check only requires at least 9 numbers in the field.
     * @return {Boolean}
     */
    is_phone() {
      let _ = this,
          replace_regex = /[^0-9+]/g,
          phone_value = _.val().replace( replace_regex, '' );

      return phone_value.length >= 9;
    }

    /**
     * Test if the field value matches a specific regex pattern.
     * @param  {String|RegExp} regex A string pattern or a regex object. 
     * @return {Boolean}
     */
    matchesPattern( regex ) {
      let _ = this;

      return regex.test( _.val() );
    }
  };

  /**
   * A single message element attached to a form field.
   * The message can represent information or an error in the value entered.
   */
  let FormFieldMessage = class FormFieldMessage {
    constructor( $field ) {
      let _ = this;

      _.$field = $field;
      
      _.element_selector = '.js-input-message';
      _.animation_speed = 250;

      _.$message = _.getElement();
    }

    /**
     * Get a jQuery element representing a 
     * message attached to the form field.
     * If there is not a message element, one will be created.
     * @return {jQuery} The message element, found or creeated.
     */
    getElement() {
      let _ = this,
          $message = _.$field.siblings( _.element_selector );

      if ( !$message.length ) {
        $message = $( `<p class="input-message js-input-message" data-field="#${ _.$field.attr('id') }" style="display:none;"></p>` );
        // Insert into DOM
        _.$field.after( $message );
      }

      return $message;
    }

    /**
     * Set the status of the message.
     * @param {String} status The status to set. Can be anything, however 
     *                        some choices are: notice, info, error.
     * @return {self}
     */
    set_status( status ) {
      let _ = this;

      _.$message.addClass( `input-message--${status} js-input-message--${status}` );

      return _;
    }

    /**
     * Set the message text.
     * @param {String} message The text to set.
     * @return {self}
     */
    set_message( message ) {
      let _ = this;

      _.$message.html( message );

      if ( !_.$message.is(':visible') ) {
        _.show();
      } else {
        _.blink();
      }

      return _;
    }

    /**
     * Show the message.
     * @return {self} 
     */
    show() {
      let _ = this;

      _.$message.slideDown( _.animation_speed );

      return _;
    }

    /**
     * Make the message blink once to attract attention.
     * @return {self} 
     */
    blink() {
      let _ = this;

      _.$message.hide().fadeIn( _.animation_speed );

      return _;
    }

    /**
     * Hide the message.
     * @return {self} 
     */
    hide() {
      let _ = this;

      _.$message.slideUp( _.animation_speed );

      return _;
    }
  };

  let ContactForm = class ContactForm {
    constructor( $form ) {
      let _ = this;

      _.$form = $form;

      _.fields = {
        firstname: new FormField( _.$form.find('#firstname') ),
        surname: new FormField( _.$form.find('#surname') ),
        email: new FormField( _.$form.find('#email') ),
        phone: new FormField( _.$form.find('#phone') ),
        message: new FormField( _.$form.find('#message') )
      };
    }

    /**
     * Validate the form.
     * @return {Boolean} True if the form validated with no errors,
     *                   false otherwise.
     */
    validate() {
      let _ = this,
          fields = _.fields,
          validated = true;


      if ( !fields.firstname.is_required_and_valid() ) {
        fields.firstname.add_error( 'Enter your first name' );
        validated = false;
      } else if( !fields.firstname.is_empty() && !fields.firstname.is_min_length() ) {
        fields.firstname.add_error( `Enter at least ${ fields.firstname.$field.attr('minlength') } characters` );
        validated = false;
      }

     if ( !fields.surname.is_required_and_valid() ) {
        fields.surname.add_error( 'Enter your surname' );
        validated = false;
      } else if( !fields.surname.is_empty() && !fields.surname.is_min_length() ) {
        fields.surname.add_error( `Enter at least ${ fields.surname.$field.attr('minlength') } characters` );
        validated = false;
      }

      if ( !fields.email.is_required_and_valid() ) {
        fields.email.add_error( 'Enter your email' );
        validated = false;
      } else if ( !fields.email.is_empty() && !fields.email.is_email() ) {
        fields.email.add_error( 'Enter a valid email' );
        validated = false;
      }

      if ( !fields.phone.is_required_and_valid() ) {
        fields.phone.add_error( 'Enter your phone number' );
        validated = false;
      } else if ( !fields.phone.is_empty() && !fields.phone.is_phone() ) {
        fields.phone.add_error( 'Enter a valid phone number' );
        validated = false;
      }

      if ( !fields.message.is_required_and_valid() ) {
        fields.message.add_error( 'Enter a message' );
        validated = false;
      }


      return validated;
    }
  };


  // Expose API
  window.FormField = FormField;
  window.FormFieldMessage = FormFieldMessage;
  window.ContactForm = ContactForm;
	
} )( jQuery, window, document );

// Theme utility functions

function rgb2hex( rgb ) {
	const regex = /rgb\((\d{1,3}),\s?(\d{1,3}),\s?(\d{1,3})\)/;
	let split = rgb.match( regex );

	split = [ parseInt(split[1]), parseInt(split[2]), parseInt(split[3]) ];
	split = split.map( (value) => {
		let hex = Number(value).toString(16);
	  if (hex.length < 2) {
	  	hex = "0" + hex;
	  }
	  return hex;
	} );

	return '#' + split.join('');
}

/**
 * Get the key for a theme color.
 * @param  {String} hex The hex code for a theme color. Should include the leading hash.
 * @return {String}     The corresponding key for this hex code 
 *                      or a blank string if it can't be found.
 */
function getThemeColorKey( hex ) {
	hex = hex.toLowerCase();

	const colors = Object.keys( wp.theme_colors ).map( e => wp.theme_colors[e] );
	const key = colors.reduce( (final, obj) => {
		return hex === obj.hex ? obj.key : final;
	}, '' );

	return key;
}

// Controller that manages the various class-based states of the main nav

( function( $, window, document, undefined ) {

	const $mainNav = $('.main-nav');
	const $mainNavItems = $('.main-nav__list > li');

	function _sanitise( $node ) {
		if ( !$node.is('.main-nav__item') ) {
			/**
			 * Go up to the containing li and back to the item
			 * The node may be a sibling of the menu item, e.g. submenu,
			 * so the only sure-fire way to locate it is to use their shared parent
			 */
			$node = $node.closest('.main-nav__list > li');
			$node = $node.find('.main-nav__item');
		}

		return $node;
	}

	const megaMenu = ( function() {
		const activeClass = 'is-open';

		function open( $navItem, eventType ) {
			$mainNav.addClass( 'has-open-menu' );

			$navItem.addClass( activeClass );
			$navItem.attr( 'aria-expanded', 'true' );
			$navItem.parent().addClass( activeClass );

			$navItem.next('.mega-menu').stop().slideDown( {
				duration: 300,
				easing: 'easeOutCubic'
			} );

			if ( 'mouseenter' == eventType ) {
				$navItem.addClass( 'is-open-from-hover' );
			}
		}

		function close( $navItem, eventType ) {
			$mainNav.removeClass( 'has-open-menu' );

			$navItem.removeClass( activeClass );
			$navItem.attr( 'aria-expanded', 'false' );
			$navItem.parent().removeClass( activeClass );

			$navItem.next('.mega-menu').stop().slideUp( {
				duration: 250,
				easing: 'easeOutCubic'
			} );

			// Always remove this class - no longer open
			$navItem.removeClass( 'is-open-from-hover' );
		}

		function isOpen( $navItem ) {
			return $navItem.hasClass( activeClass );
		}

		return {
			open,
			close,
			isOpen
		};
	} )();

	function closeOpenMenus() {
		const $openMenus = $mainNavItems.filter('.is-open');
		$openMenus.each( (index, menu) => {
			megaMenu.close( _sanitise( $(menu) ) );
		} );
	}

	if ( $mainNavItems.length ) {
		
		$mainNavItems.hover( 
			// mouseover
			(event) => {
				ScrollVelocity.disable();
				megaMenu.open( _sanitise( $(event.target) ), event.type );
			},
			// mouseout
			(event) => {
				ScrollVelocity.enable();
				megaMenu.close( _sanitise( $(event.target) ), event.type );
			}
		);

		let stopFollowingEvents = false;

		$mainNav.on( 'touchend', (event) => {
			closeOpenMenus();
		} );

		$mainNavItems.on( 'touchend', (event) => {
			let $target = $(event.target);

			if ( !$target.is( '.main-nav__item' ) ) {
				$target = $target.closest('.main-nav__item');
			}

			const isOpen = megaMenu.isOpen( $target );

			if ( isOpen ) {
				megaMenu.close( $target, 'touchend' );
				stopFollowingEvents = true;
			}

			event.stopPropagation();
		} );

		$mainNavItems.click( (event) => {
			const $target = _sanitise( $(event.target) );
			const isOpen = megaMenu.isOpen( $target );

			if ( isOpen && !$target.hasClass( 'is-open-from-hover' ) ) {
				ScrollVelocity.enable();

				megaMenu.close( $target, event.type );
			} else if ( !stopFollowingEvents ) {
				ScrollVelocity.disable();

				closeOpenMenus();
				megaMenu.open( $target, event.type );
			}

			stopFollowingEvents = false;
		} );	

	}
	
} )( jQuery, window, document );

// Smoothly scroll to anchor links with specific duration.

( function( $, window, document, undefined ) {

	const SmoothScroll = class SmoothScroll {
		/**
		 * Setup the class and properites.
		 * @param  {Number} duration  The duration of the scroll, in ms.
		 * @param  {Number} offset 		The amount, in pixels, to offset the final position.
		 *                           	Negative values will stop before the y specified,
		 *                         	  Positive values will stop after the y specified.
		 */
		constructor( duration = 400, offset = 0 ) {
			const _ = this;

			_.$root = $('html, body');
			_.duration = duration;
			_.offset = offset;
		}

		/**
		 * Scroll to a specific element.
		 * @param  {jQuery|String} $element The element to scroll to. 
		 *                                  Accepts a jQuery element or a selector string.
		 * @return {self}
		 */
		toElement( $element ) {
			const _ = this;

			$element = $( $element );

			if ( $element.length ) {
				_._scroll( $element.offset().top );
			}
			
			return _;
		}

		/**
		 * Scroll to a position on the screen.
		 * @param  {Number} y The y coordinate to scroll to.
		 * @return {self}
		 */
		toPosition( y ) {
			const _ = this;

			_._scroll( y );

			return _;
		}

		/**
		 * Animate the current scroll position to a y coordinate.
		 * The final scroll position will be offset by the class offset property.
		 * @param  {Number} y The y coordinate to scroll to.
		 * @return {self}
		 */
		_scroll( y ) {
			const _ = this;

		  _.$root.eq(0).trigger( 'start.SmoothScroll' );

			_.$root.animate( 
				{
		      scrollTop: y + _.offset
	    	}, 
	    	{
	    		duration: _.duration,
	    		/**
	    		 * A completion function triggered when the scroll finishes.
	    		 * Use `$('html').on('complete.SmoothScroll')` to listen for it.
	    		 * @param {Event} event The event object triggered.
	    		 */
	    		complete: debounce( () => {
		    		_.$root.eq(0).trigger( 'end.SmoothScroll' );
		    	}, 50 ) 
	    	}
	    );

	    return _;
		}

		/**
		 * Set a new offset for method calls to this class.
		 * The current offset will be stored on the class so that
		 * it can be restored later on.
		 * @param {Number} offset The amount of pixels to offset the final scroll position.
		 *                        Negative values will stop before the top of the element,
		 *                        positive values will stop after the top of the element. 
		 */
		setTempOffset( offset ) {
			const _ = this;

			// Set private property offset to current class property
			// Can be restored later
			_._offset = _.offset;
			// Set class property to passed offset
			_.offset = offset;

			return _;
		}

		/**
		 * Restore the offset applied earlier by setTempOffset().
		 * @return {self}
		 */
		restoreOffset() {
			const _ = this;
			
			// Check for existence of temp property
			if ( null !== _._offset && undefined !== _._offset ) {
				// Set class property to temp property
				_.offset = _._offset;
				// Unset temp property
				_._offset = null;
			}

			return _;
		}
	};


	// Expose API
	window.SmoothScroll = new SmoothScroll( 400, $('.site-header').outerHeight() - 60 );

} )( jQuery, window, document );

// Mobile main nav controller

( function( $, window, document, undefined ) {

	const mobileMenu = ( function() {

		const $toggle = $('.js-main-nav-toggle');
		const $menu = $( $toggle.data('toggle') );

		let isOpen = false;

		const animationSpeed = 300;

		// Add ARIA to buttons on load
		$('.js-main-nav-toggle, .js-main-nav-mobile-accordian').each( (index, button) => {
			$(button).attr( 'aria-expanded', $(button).hasClass('is-current') ? 'true' : 'false' );
		} );

		function open() {
			ViewportScroll.disable();
			ScrollVelocity.disable();

			$toggle.attr( 'aria-expanded', 'true' );
			$menu.slideDown( animationSpeed );

			isOpen = true;
		}

		function close() {
			ViewportScroll.enable();
			ScrollVelocity.enable();

			$toggle.attr( 'aria-expanded', 'false' );
			$menu.slideUp( animationSpeed );

			isOpen = false;
		}

		function toggle() {
			if ( isOpen ) {
				close();
			} else {
				open();
			}
		}

		$toggle.click( (event) => {
			toggle();
		} );
		
		return {
			open,
			close,
			isOpen: () => {
				return isOpen;
			}
		};

	} )();

	// Expose API
	window.mobileMenu = mobileMenu;

	$('.js-main-nav-mobile-accordian').click( (event) => {
		let $target = $(event.target);

		if ( !$target.is('.js-main-nav-mobile-accordian') ) {
			$target = $target.closest('.js-main-nav-mobile-accordian');
		}

		const $list = $target.next('div');

		if ( $list.length ) {
			$target.attr( 'aria-expanded', (_, attr) => attr != 'true' );
			$list.slideToggle( 400 );
		}
	} );

} )( jQuery, window, document );

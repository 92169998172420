// Init Image Carousel components

( function( $, window, document, undefined ) {

	const $components = $('.js-image-carousel');

	function getCenterPadding() {
		const vw = $(window).width();
		const itemGutter = vw * 0.05;
		const maxWidth = $components.find('figure').outerWidth();


		let centerPadding = (vw - maxWidth - itemGutter * 2) / 2;

		return centerPadding + 'px';
	}

	const prevArrowHTML = `<button type="button" class="slick-prev"><span class="sr-only">Previous slide</span><svg class="svg-icon svg-chevron svg-chevron--left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.84 9.46" aria-hidden="true" focusable="false"><path d="M5.84,8.35,2.23,4.73,5.84,1.11,4.73,0,0,4.73,4.73,9.46Z"/></svg></button>`;
	const nextArrowHTML = `<button type="button" class="slick-next"><span class="sr-only">Next slide</span><svg class="svg-icon svg-chevron svg-chevron--right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.84 9.46" aria-hidden="true" focusable="false"><path d="M0 1.11l3.61 3.62L0 8.35l1.11 1.11 4.73-4.73L1.11 0z"></path></svg></button>`;

	const params = {
		autoplay: true,
		autoplaySpeed: 4000,
		arrows: true,
		prevArrow: prevArrowHTML,
		nextArrow: nextArrowHTML,
		dots: false,
		adaptiveHeight: false,
		draggable: true,
		centerMode: true,
		centerPadding: getCenterPadding(),
		responsive: [
			{
				breakpoint: 1260,
				settings: {
					centerMode: false,
					centerPadding: '0px'
				}
			}
		]
	};

	$components.slick( params );

	$(window).on( 'resize', debounce( (event) => {
		$components.slick( 'slickSetOption', 'centerPadding', getCenterPadding(), true );
	}, 150 ) );
	
} )( jQuery, window, document );

// Init WOW.js

( function( $, window, document, undefined ) {
	
	var boxClass = 'js-wow',
			animateClass = 'is-animating',
			wow = new WOW( {
				boxClass,
				animateClass
			} ),
			$wowElements = $( '.' + boxClass );

	// Repaint specific elements when animation finishes
	$wowElements.on( 'animationend oanimationend webkitAnimationEnd MSAnimationEnd', ( event ) => {

		let $this = $(event.target);

		if ( $this.is('.jw-fadeOut') ) {
			$this.css('opacity', 0);
		}

	} );

	// Show immediately for any elements already scrolled past
	// $(document).on( boxClass + '-initialised', function() {

	// 	$wowElements.each( function( index, node ) {
	// 		var $this = $(node);

	// 		if ( $(window).scrollTop() > 0 && hasBeenScrolledPast( $this ) ) {
	// 			wow.show( node );
	// 		}
	// 	} );

	// } );

	wow.init();

} )( jQuery, window, document );

// Summary collapsible section controller

( function( $, window, document, undefined ) {

	const $summaries = $('.image-summary');

	$summaries.each( (index, summary) => {
		const $summary = $(summary);
		const $button = $summary.find('button');
		const $content = $button.siblings('.image-summary__content');

		// Enhance with ARIA
		$button.attr( 'aria-expanded', false );
		$content.get(0).hidden = true;

		// Attach toggle handler
		$button.on( 'click', (event) => {
			$button.attr( 'aria-expanded', ( _, attr ) => 'true' != attr );
			$content.get(0).hidden = !$content.get(0).hidden;
		} );
	} );
	
} )( jQuery, window, document );
